* {
    box-sizing: border-box;
}
.pull-right {
    float: right;
}
.text-muted {
    color:rgba(0, 0, 0, .6);
}
.App-content {
    text-align: left;
    padding: 1em;
    transition: all .3s ease-in;
    padding-top: 6em;
}
.App-content>.head>small {
    font-size: small;
}
.App-content>.head>.status {
    background: #4caf50;
    color: white;
    padding: .2rem .4rem;
    border-radius: 40px;
    font-size: small;
    margin-left: 1rem; 
}
.App-content>#tabs{
    display: block;
    width: 100%;
    text-align: right;
    border-bottom: solid 1px #eee;
    padding-bottom: 0.22em;
}
.App-content>#tabs>a {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border: solid 1px #ddd;
    padding: .6rem .8rem; 
    font-size: small;
    text-decoration: none;
    border-bottom: white;
}
.App-content>#tabs>a:hover {
    padding-top: .8rem;
}
.App-content>#tabs>a + a {margin-left: .5rem;}
.App-content>#tabs.eco>a.eco, .App-content>#tabs>a:first-child, .App-content>#tabs.net>a.net, .App-content>#tabs.gdf>a.gdf{
    background-color: white;
    color: black;
}
.App-content>#tabs.eco>a:first-child, .App-content>#tabs.net>a:first-child, .App-content>#tabs.gdf>a:first-child {
    background-color: initial;
    color: blue;
}
.card {
    padding: 1rem;
    border-radius: 4px;
    border: solid 1px #eee;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
}
.App-items {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: minmax(0, 1fr);
}
@media screen and (min-width: 768px) {
    .App-items { grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); }
}
@media screen and (min-width: 1100px) {
    .App-items { grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); }
}
@media screen and (min-width: 1300px) {
    .App-items { grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); }
}
@media screen and (min-width: 1800px) {
    .App-items { grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); }
}
@media print {
    .App-content {padding-top: 1em;}
}