.App {
  text-align: center;
  max-width: 100vw;
  margin: 0px auto;
}

a {
  color: #0271ff;
}
a.click {
  cursor: pointer;
}
/*
a[target]::after {
  content: "↗️";
  padding-left: .2rem;
  font-size: small;
}
*/

.App-header>header, .App-content {
  margin: 0px auto;
}

@media screen and (min-width: 768px) {
  .App-header>header, .App-content {width: 748px;}
}
@media screen and (min-width: 1100px) {
  .App-header>header, .App-content {width: 1080px;}
}
@media screen and (min-width: 1280px) {
  .App-header>header, .App-content {width: 1260px;}
}
@media screen and (min-width: 1600px) {
  .App-header>header, .App-content {width: 1580px;}
}
@media screen and (min-width: 1800px) {
  .App-header>header, .App-content {width: 1780px;}
}

.App-title, .name {
  font-family: 'Varela Round', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}