.status {
    text-transform: uppercase;
    cursor: default;
}
.status[title="ok"] {
    color: green;
}
.status[title="maintenance"] {
    color: orange;
}
.status[title="error"] {
    color: red;
}
