:root {
    --color-ok: #2b45d4;
    --color-error: #ff0073;
}
.item {
    width: 100%;
    page-break-inside: avoid;
}
.card-title {
    margin-top: 0;
    margin-bottom: 0;
}
.card-title>a {
    cursor: pointer;
    text-decoration: none;
}
a.card-link {
    text-decoration: none;
    font-weight: bold;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
    font-size: small;
}
.card-footer {
    margin-bottom: 0;
}
.card > h3 {
    display: flex;
}
.card > h3 > .name {
    flex: 1;
}
.detail .check-graph {
    flex: 1;
}
.check-graph {
    display: flex;
    flex-direction: row;
    padding: 2.18em 0;
    max-width: 100%;
    overflow: hidden;
}
.check-graph>div {
    flex:1;
    height: 24px;
    background-color: #2b45d4;
    color: white;
    min-width: 1px;
}
.info-check-graph>small {font-weight: bold;}
.item.card {
    --color-0: var(--color-ok);
    --color-1: var(--color-error);
}
/*.item.card.Ping {
    --color-0: var(--color-error);
    --color-1: var(--color-ok);
}*/
small.color_0 {
    color: var(--color-0);
}
small.color_1 {
    color: var(--color-1);
}
.check-graph>div.color_1 {
    background-color: var(--color-1);
}
.color_1+.color_0, .color_0+.color_1 {
    border-left: solid 2px white;
}
.color_1+.color_0:after, .color_0+.color_1:after {
    content: attr(data-title);
    background: white;
    padding: .3rem;
    box-shadow: 1px 2px 2px rgb(0 0 0 / 20%);
    border-radius: 4px;
    min-width: 10px;
    position: relative;
    left: -1.75rem;
}
.color_1+.color_0:after {
    top: 2rem;
    background-color: var(--color-0);
}
.color_0+.color_1:after {
    top: -1.9rem;
    background-color: var(--color-1);
}
.flex {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
}
.flex-space-between {
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
}
.flex-space-between>*:first-child {
    margin-right: .5rem;
}
.flex-space-between>*:last-child {
    margin-left: .5rem;
}