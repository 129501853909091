
.App-logo {
    height: 10vh;
    padding: 0 1em;
    pointer-events: none;
}

.maida-logo {
    height: 42px;
    pointer-events: none;
    float: right;
}

.maida-logo, .App-title {
    padding: 0 1rem;
}
  
.App-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: calc(10px + 2vmin);
    box-shadow: 1px 2px 2px rgb(0 0 0 / 20%);
    position: fixed;
    width: 100%;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 999;
    padding: 0 1rem;
}

.App-header a {
    text-decoration: none;
}
.App-header a[target]::after {
    content: "";
}
  
.App-title {
    font-weight: bold;
    font-size: 2rem;
    color: #2b45d4;
}
.App-title > img {
    height: 2rem;
    vertical-align: text-top;
}
.divider {
    border-left: solid 1px rgba(0,0,0,.3);
    width: 0;
    height: 64px;
}
@media print {
    .App-header {
        position: relative;
        box-shadow: none;
    }
}