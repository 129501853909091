.loader {
    margin: 0px auto;
    padding: 1em;
    text-align: center;
}
.loader.absolute {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -2.5rem;
}